<template>
    <b-tabs class="mb-5" align="right" v-model="selected" style="margin-top: -30px">
      <b-tab v-for="l in this.languages" v-bind:key="l.locale" :title="l.locale" @click="setLanguage(l.locale)"></b-tab>
    </b-tabs>
</template>

<script>
//import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "FormControlLanguages",
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  beforeMount() {
    if(this.languages.length > 0) {
      this.selected = this.languages.findIndex(lang => lang.locale == i18nService.getActiveLanguage());
    }
  },
  created() {
    this.getLanguages();
  },
  data() {
    return {
      languages: [],
      selected_languages: i18nService.getActiveLanguage(),
      selected: 0
    }
  },
  methods: {
    getLanguage() {
      return this.selected_languages;
    },
    setLanguage(lang) {
      this.selected_languages = lang;
      this.$emit('changeLanguage', this.selected_languages);
    },
    isSelectedLanguage(lang) {
      return (lang==i18nService.getActiveLanguage()) ? 'active' : '';
    },
    getLanguages() {
      this.languages = this.getOrganization.data.languages;
    }
  }
};
</script>
