<template>
  <div class="row col-12 justify-content-end pr-0 mr-0">
    <span v-for="l in this.languages" v-bind:key="l.locale" :title="l.locale">
      <div class="symbol symbol-80 symbol-xxl-100 mr-2 align-self-start align-self-xxl-center">
        <button type="button" class="btn btn-icon btn-primary ml-1" :style="{'background-color': l.primaryColor + '!important'}" @click="onClick(l.locale)">
          {{l.locale}}
        </button>
        <i :class="getClass(l.locale)"></i>
      </div>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  name: "ConfirmCompleteLanguages",
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  props: {
    localizations: {},
    id: {}
  },
  created() {
    this.getLanguages();
  },
  data() {
    return {
      languages: []
    }
  },
  methods: {
    onClick(locale) {

      if(this.localizations != undefined) {

        if (this.localizations[this.localizations.findIndex(x => x.locale == locale)] != undefined) {

          Swal.fire({
            title: this.$t("CONFIRMCOMPLETELANGUAGES.ALERT1TITLE"),
            text: ((this.localizations[this.localizations.findIndex(x => x.locale == locale)].recordNative) ? this.$t("CONFIRMCOMPLETELANGUAGES.ALERT1") : this.$t("CONFIRMCOMPLETELANGUAGES.ALERT2")) + ' ' + locale + '?',
            icon: "warning",
            showCancelButton: true,
            cancelButtonClass: "btn btn-secondary",
            cancelButtonText: this.$t("CONFIRMCOMPLETELANGUAGES.CANCEL"),
            confirmButtonClass: "btn btn-primary"
          })
              .then((result) => {
                if (result.isConfirmed) {
                  Swal.fire('', '', 'success');

                  console.log("ID", this.id);

                  return new Promise((resolve, reject) => {

                    var endpoint = this.generateUrl('ideas-expanded/' + this.id, false);
                    //generateUrl is done
                    ApiService.get(this.$apiResourceOpen, endpoint)
                        .then(res => {

                          var ideaToSave = res.data;

                          console.log("INDEX", this.localizations.findIndex(x => x.locale == locale));

                          ideaToSave.localizations[this.localizations.findIndex(x => x.locale == locale)].recordNative = !this.localizations[this.localizations.findIndex(x => x.locale == locale)].recordNative;
                          this.localizations[this.localizations.findIndex(x => x.locale == locale)].recordNative = !this.localizations[this.localizations.findIndex(x => x.locale == locale)].recordNative;

                          console.log("IDEA TO SAVE", ideaToSave);

                          return new Promise((resolve, reject) => {
                            ApiService.put(this.$apiResourceOpen + "/ideas-unassociated-locsnode", ideaToSave)
                                .then(res2 => {
                                  if (res2.data.id > 0) {
                                    console.log("SAVED IDEA", res2.data);
                                    resolve(1);
                                  }
                                })
                                .catch((e) => {
                                  console.log("ERROR", e.message);
                                  reject(0);
                                });
                          });
                        })
                        .catch(() => {
                          reject(0);
                        });
                  });
                }
              });
        }
      }
    },
    getLanguages() {
      this.languages = this.getOrganization.data.languages;
    },
    getClass(locale) {

      if(this.localizations && this.localizations != undefined && this.localizations.find(loc => loc.locale == locale) != undefined) {

        return (this.localizations.find(loc => loc.locale == locale).recordNative) ? 'symbol-badge bg-success' : 'symbol-badge bg-danger';
      }

      return 'symbol-badge bg-danger';
    }
  }
};
</script>
